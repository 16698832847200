import request from '@/utils/request'

export function getBalance() {
    return request({
        url: "/customer-balance/balance/",
        method: 'get'
    })
}

export function getWebsiteBalance(id) {
    return request({
        url: "/customer-balance/website-balance/" + id,
        method: 'get'
    })
}

export function setBalance(data) {
    return request({
        url: "/customer-balance/save/",
        method: 'post',
        data
    })
}
export function pdfDownload(id) {
    let query = {id:id}
    return request({
        url: "/customer-balance/pdf",
        method: 'get',
        params: query
    })
}
export function exportRefundsDownload(data) {
    let query = {
        searchCriteria: {
            sortOrders: [
                {
                    field: data.sortBy,
                    direction: data.descending ? 'DESC' : 'ASC'
                }

            ],
            filterGroups: data.filter
        }
    }
    return request({
        url: "customer-balance/export-refunds",
        method: 'get',
        params: query,
    })
}

export function getAllBalances(data) {
    let query = {
        searchCriteria: {
            currentPage: data.pageNumber,
            pageSize: data.pageSize,
            sortOrders: [
                {
                    field: data.sortBy,
                    direction: data.descending ? 'DESC' : 'ASC'
                }

            ],
            filterGroups: data.filter
        }
    }
    return request({
        url: "/customer-balance/history",
        method: 'get',
        params: query
    })
}

export function getAllBalancesType() {
    return request({
        url: "/customer-balance/all-type",
        method: 'get'
    })
}

export function setPayment(data) {
    return request({
        url: "/customer-balance/payment/",
        method: 'post',
        data
    })
}
//to remove setPaymentOld
export function setPaymentOld(data) {
    return request({
        url: "/customer-balance/payment/old",
        method: 'post',
        data
    })
}

export function setPaymentError(data) {
    return request({
        url: "/customer-balance/payment/error",
        method: 'post',
        data
    })
}

export function setPaymentCancel(data) {
    return request({
        url: "/customer-balance/payment/cancel",
        method: 'post',
        data
    })
}
